import * as React from 'react';
import { ReactNode } from 'react';
import Header from './Header';
// @ts-ignore
import interceptor from '../../interceptor'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { useAuthentication } from '../../contexts/AuthenticationContext';

const Layout = (props: LayoutProps) => {
    const { authInfo } = useAuthentication();

    const isAuthenticated = () => {
        return authInfo?.username !== null;
    };

    const showFreshdeskWidget = () => {
        return null;
    };

    return (
        <main>
            {isAuthenticated() && !props.hideHeader && <Header />}
            <div className={`container-fluid body-container`}>
                <div className="row">{props.children}</div>
            </div>
            {isAuthenticated() && showFreshdeskWidget()}
        </main>
    );
};

export interface LayoutProps {
    hideHeader?: boolean;
    children?: ReactNode;
}

export default Layout;
