import * as React from 'react';
import { Fragment } from 'react';
import ImageUploading, { ImageListType, ImageType, ErrorsType } from 'react-images-uploading';
import { ReactComponent as UploadIcon } from '../../content/icons/Icon-Image-Upload.svg';
import { ReactComponent as AlertIcon } from '../../content/icons/Icon-Alert.svg';
import { Button } from 'reactstrap';
import ValidationMessage from './ValidationMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExportInterface } from 'react-images-uploading/dist/typings';
import { ReactComponent as DeleteIcon } from '../../content/icons/Icon-Delete.svg';

function ImageUpload(props: ImageUploadProps): JSX.Element {
    const intl = useIntl();

    function getImageErrorMessage(errors: ErrorsType): JSX.Element | undefined {
        if (!errors) {
            return;
        }

        if (errors.maxFileSize) {
            return (
                <ValidationMessage message={intl.formatMessage({ id: 'CommonComponents.ImageUpload.MaxFileSize' })} />
            );
        }

        if (errors.acceptType) {
            return (
                <ValidationMessage message={intl.formatMessage({ id: 'CommonComponents.ImageUpload.InvalidType' })} />
            );
        }
    }

    function uploadImage(imageList: ImageListType, onImageUpload: Function) {
        if (imageList.length === 0) {
            onImageUpload();
        }
    }

    // Handle multiple image upload
    if (props.maxImages) {
        return (
            <ImageUploading
                multiple
                value={props.images}
                onChange={props.onChange}
                dataURLKey="data_url"
                maxFileSize={5242880}
                acceptType={['jpg', 'jpeg', 'png']}
                maxImages={props.maxImages}
            >
                {({ imageList, onImageUpload, isDragging, dragProps, errors }: ExportInterface) => (
                    <Fragment>
                        <div>
                            <div
                                className={'clickable'}
                                style={isDragging ? { borderColor: 'green' } : {}}
                                onClick={() => onImageUpload()}
                                {...dragProps}
                                hidden={imageList.length >= 6 || props.isDisabled}
                            >
                                <div className="multiple-upload-icon-container">
                                    <UploadIcon />
                                    <div>
                                        <FormattedMessage id="CommonComponents.ImageUpload.Title" />
                                    </div>
                                </div>
                            </div>
                            {imageList.map((image: ImageType, index: number) => {
                                return (
                                    <div key={index} className="multiple-image-item">
                                        <img key={index} src={image.dataURL} alt="" width="200" height="125" />

                                        <Button
                                            color="outline-primary"
                                            onClick={() => {
                                                props.onDelete!(image);
                                            }}
                                            className="delete-image-button"
                                            hidden={props.isDisabled}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </div>
                                );
                            })}
                            {imageList.length >= 6 ? (
                                <div>
                                    &nbsp;
                                    <AlertIcon /> <FormattedMessage id="CommonComponents.ImageUpload.MaxImagesAlert" />{' '}
                                </div>
                            ) : (
                                <div> </div>
                            )}
                        </div>
                        <div className="image-validation-message">{getImageErrorMessage(errors)}</div>
                    </Fragment>
                )}
            </ImageUploading>
        );
    } else {
        return (
            <ImageUploading
                value={props.images}
                onChange={props.onChange}
                dataURLKey="data_url"
                maxFileSize={5242880}
                acceptType={['jpg', 'jpeg', 'png']}
                disabled={props.isDisabled}
            >
                {({ imageList, onImageUpload, onImageUpdate, isDragging, dragProps, errors }: ExportInterface) => (
                    <Fragment>
                        <div className="upload-image-wrapper">
                            <div
                                className={'image-item clickable'}
                                style={isDragging ? { borderColor: 'green' } : {}}
                                onClick={() => uploadImage(imageList, onImageUpload)}
                                {...dragProps}
                            >
                                {imageList.length === 0 && !props.isDisabled && (
                                    <div className="upload-icon-container">
                                        <UploadIcon />
                                        <div>
                                            <FormattedMessage id="CommonComponents.ImageUpload.Title" />
                                        </div>
                                    </div>
                                )}

                                {imageList.map((image: ImageType, index: number) => {
                                    return (
                                        <div key={index}>
                                            <img key={index} src={image.data_url} alt="" width="178" height="178" />

                                            <Button
                                                color="outline-primary"
                                                onClick={() => onImageUpdate(0)}
                                                className="upload-new-image-button"
                                                hidden={props.isDisabled}
                                            >
                                                <FormattedMessage id="CommonComponents.ImageUpload.Icon" />
                                            </Button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="image-validation-message">{getImageErrorMessage(errors)}</div>
                    </Fragment>
                )}
            </ImageUploading>
        );
    }
}

interface ImageUploadProps {
    images?: ImageListType;
    onChange?(imageList: ImageListType): void;
    maxImages?: number;
    onDelete?(image: ImageType): void;
    isDisabled?: boolean;
}

export default ImageUpload;
