import * as React from 'react';
import Switch from 'react-switch';

const Toggle = (props: ToggleProps) => {
    return (
        <label className={props.disabled ? 'disabled' : ''}>
            <span>{props.label}</span>

            <Switch
                disabled={props.disabled}
                onChange={props.handleChange}
                checked={props.checked}
                className={props.className ? `toggle-switch ${props.className}` : 'toggle-switch'}
                borderRadius={5}
                handleDiameter={28}
                height={32}
                width={80}
                onColor={props.oncolor ? props.oncolor : '#054169'}
                offColor="#B2B2B1"
                uncheckedIcon={<span className="toggle-option-label">{props.uncheckedLabel}</span>}
                checkedIcon={<span className="toggle-option-label">{props.checkedLabel}</span>}
            />
        </label>
    );
};

export interface ToggleProps {
    label?: string;
    checked: boolean;
    handleChange: () => void | undefined;
    checkedLabel?: string;
    uncheckedLabel?: string;
    className?: string;
    disabled: boolean;
    oncolor?: string;
}

export default Toggle;
