import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import ApiLogService from '../../services/ApiLogService';
import environment from '../../enums/environment.js';
import LogTable from '../common/LogTable';
import AppLogDetails from './AppLogDetails';
import { FormattedMessage, useIntl } from 'react-intl';
import ApiRequestLogEntry from '../../model/apiLogs/ApiRequestLogEntry';
import App from '../../model/appManagement/App';

interface AppApiLogsProps {
    application: App | undefined;
    isTestEnvironment: boolean;
}
function AppApiLogs({ application, isTestEnvironment }: AppApiLogsProps): JSX.Element {
    const appId = application?.id;
    const env = isTestEnvironment ? environment.test : environment.production;

    const [apiLogEntries, setApiLogsEntries] = useState<ApiRequestLogEntry[]>([]);
    const [nextPageUrl, setNextPageUrl] = useState<string>();
    const [isLoading, setIsLoading] = useState(true);
    const intl = useIntl();
    const [filter, setFilter] = useState({
        responseStatus: null,
        dateTimeFrom: null,
        dateTimeTo: null,
    });
    const [isDisplayingLogEntry, setIsDisplayingLogEntry] = useState(false);
    const [requestId, setRequestId] = useState(null);

    useEffect(() => {
        if (appId) {
            setApiLogsEntries([]);
            setIsLoading(true);
            ApiLogService.findApiRequestLogEntries(
                appId,
                filter.responseStatus,
                filter.dateTimeFrom,
                filter.dateTimeTo,
                env
            )
                .then((response) => {
                    if (!('errorMessage' in response)) {
                        setApiLogsEntries(response.data);
                        setNextPageUrl(response.nextPageUrl);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [appId, filter, env]);

    const columns = [
        {
            name: intl.formatMessage({ id: 'AppManagement.ApiLogs.TimeStamp' }),
            className: 'log-table__timestamp-column',
            displayFormat: (x: any) => {
                const statusDotClassName = `status-dot ${x.responseStatus >= 400 ? 'status-dot--fail' : ''}`;
                return (
                    <Fragment>
                        <div className={statusDotClassName}></div>
                        {x.timestamp.replace('T', ' ').replace(/\.\d+/, '')}
                    </Fragment>
                );
            },
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.ApiLogs.Summary' }),
            selector: (x: any) => (
                <Fragment>
                    <span className="log-table__request-summary" title={x.requestSummary}>
                        {x.requestSummary}
                    </span>
                </Fragment>
            ),
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.ApiLogs.Status' }),
            selector: (x: any) => x.responseStatus,
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.ApiLogs.Time' }),
            selector: (x: any) => x.elapsedMilliseconds,
        },
        {
            name: '',
            displayFormat: (x: any, index: any) => (
                <button
                    className="log-table__goto-detail-page float-right button-link"
                    onClick={() => {
                        displayLogEntry(x.id);
                    }}
                >
                    <img src="/images/Chevron.svg" className="rotate-90deg" alt="" />
                </button>
            ),
        },
    ];

    const handleLoadMoreClick = async () => {
        if (nextPageUrl) {
            setIsLoading(true);
            ApiLogService.loadNextPage(nextPageUrl)
                .then((response) => {
                    if (!('errorMessage' in response)) {
                        // append the results to the current api log list.
                        setApiLogsEntries([...apiLogEntries, ...response.data]);
                        setNextPageUrl(response.nextPageUrl);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                    window.scrollTo({
                        top: document.documentElement.scrollHeight,
                        behavior: 'auto',
                    });
                });
        }
    };

    function displayLogEntry(id: any) {
        setIsDisplayingLogEntry(true);
        setRequestId(id);
    }

    const renderGotoDetailPage = (id: any) => {
        return (
            <button
                className="log-table__goto-detail-page float-right"
                onClick={() => {
                    displayLogEntry(id);
                }}
            >
                <img src="/images/Chevron.svg" className="rotate-90deg" alt="" />
            </button>
        );
    };

    return (
        <div className="logs-page">
            <h1>
                <FormattedMessage id="AppManagement.ApiLogs.PageHeader" />
            </h1>
            {!isDisplayingLogEntry && (
                <LogTable
                    tableName={intl.formatMessage({ id: 'AppManagement.ApiLogs.PageHeader' })}
                    columns={columns}
                    data={apiLogEntries}
                    isLoading={isLoading}
                    nextPageUrl={nextPageUrl}
                    handleLoadMoreClick={handleLoadMoreClick}
                    filter={filter}
                    setFilter={setFilter}
                    application={application}
                    expandRow={renderGotoDetailPage}
                />
            )}
            {isDisplayingLogEntry && (
                <AppLogDetails
                    application={application}
                    requestId={requestId}
                    environment={env}
                    setIsDisplayingLogEntry={setIsDisplayingLogEntry}
                />
            )}
        </div>
    );
}

export default AppApiLogs;
